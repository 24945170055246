.primary-error-page {
  width: 100%;
  height: 100vh;
  background-color: #193149;

  .message-wrapper {
    padding: 0px;
    margin: 0px;
    text-align: center;

    .error-message {
      color: #ffffff;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
}
