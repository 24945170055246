.new-customer {
  margin: -10px;
  .nav-link {
    padding: 6px 16px;
  }
  .tab-content {
    min-height: 300px;
    padding: 10px;
  }

  .contact-person-wrapper {
    background-color: #f1f2f8;
    padding: 5px;
    border-radius: 6px;
    p {
      margin-bottom: 0;
    }
  }
}
