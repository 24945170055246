.print-preview {
  animation: fadeInUp;
  animation-duration: 300ms;

  .modal-content {
    border-radius: 5px;
    background-color: rgb(82, 86, 89);

    .modal-header {
      background-color: rgb(50, 54, 57);
      padding: 5px 5px;
      border-radius: 0;
      border-bottom: none;
      .modal-title {
        color: #fff;
        font-size: 14px;
      }
    }

    .modal-body {
      background-color: rgb(82, 86, 89);
    }

    .modal-footer {
      background-color: rgb(50, 54, 57);
      color: #fff;
      padding: 5px 5px;
      border-radius: 0;
      border-top: none;
    }
  }
}
