.primary_model {
  animation: zoomIn;
  animation-duration: 300ms;

  .modal-header {
    padding: 5px 10px;
    background-color: #2a579a;
    border-color: #2a579a;

    .modal-title {
      font-size: 14px;
      color: #fff;
      svg {
        height: 18px;
        width: 18px;
        vertical-align: text-bottom;
        margin-right: 5px;
      }
    }

    .close {
      svg {
        fill: #fff;
      }
    }
  }

  .modal-footer {
    padding: 5px;
  }
}
