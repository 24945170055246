.so-list {
  padding-top: 15px;

  .action-table-wrapper {
    margin: 5px;
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;

    .action-table-title {
      background-color: #f2f1f7;
      padding: 5px;
      border-radius: 3px;
      text-align: center;
      h3 {
        margin: 0;
        font-size: 18px;
        color: #7c7c7c;
      }
    }
    .action-table {
      padding: 10px;
      .list-table {
        overflow: hidden;
      }
      .tbl-so-no-col {
        padding-bottom: 3px;
        h6 {
          margin: 0;
        }
      }
      .tbl-customer-col {
        padding-bottom: 3px;
        color: #7c7c7c;
        p {
          margin: 0;
          color: #0e0e0e;
        }
      }
    }
  }
}
