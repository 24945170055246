.receive-payment{
    .invoice-info{
        border: 1px solid #dee2e6;
        padding: 5px;
        border-radius: 3px;
        margin-bottom: 5px;

        .invoice-info-label{
            font-weight: 600;
            width: 100%;
        }
        .invoice-info-value{
            font-weight: 600;
            text-align: right;
        }
    }
    .pay-history-list{
        min-height: 200px;
        .pay-history-table{
            thead th{
                font-weight: 400;
            }
        }
    }
    

    .cal-wrapper{
        background-color: #f7f7f7;
        padding: 8px;
        margin-top: 10px;
        height: 86px;

        .form-group{
            margin-bottom: 5px;

            #paid_amount{
                font-weight: 600;
            }
        }
    }
}