.primary-list {
  .action-table {
    .tbl-supplier-col {
      padding-bottom: 3px;
      h6 {
        margin: 0;
      }
    }
  }
  .right-content {
    .contact-person {
      background-color: #f2f8ff;
      height: 65px;
      border-radius: 6px;
      padding: 3px 6px;
      margin-bottom: 6px;
      p {
        margin-bottom: 0px;
      }
    }
  }
}
