.default-search-bar {
  margin-bottom: 0px;
  margin-top: 5px;
  margin-left: 10px;
  .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }
}

.default-search-bar .form-control {
  padding-left: 2.375rem;
}
