.notifier {
  width: 300px;
  min-height: 80px;
  background-color: #272822;
  color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  z-index: 1999;
  animation: fadeInUp;
  animation-duration: 300ms;
  margin-bottom: 6px;
  padding: 5px;

  .notifier-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .notifier-title {
      font-size: 16px;
      svg {
        fill: #8a8a8a;
        height: 20px;
        width: auto;
        margin-bottom: 5px;
      }
    }
    .close-btn {
      margin-left: auto;
      background-color: #444444;
      height: 23px;
      width: auto;
      border: none;
      border-radius: 50%;
      padding: 0;
      svg {
        fill: #8a8a8a;
        height: 23px;
        width: auto;
      }
      svg:hover {
        fill: #f5f5f5;
      }
    }
  }
  .notifier-content {
    ul {
      margin: 0;
      padding-left: 20px;
      li {
        margin: 0;
      }
    }
  }

  &.info {
    .notifier-title {
      svg {
        fill: #b6d6f8;
      }
    }
  }
  &.success {
    .notifier-title {
      svg {
        fill: #baffb4;
      }
    }
  }
  &.error {
    .notifier-title {
      svg {
        fill: #ffb4b4;
      }
    }
  }
}
