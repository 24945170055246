.user-login {
  width: 100%;
  height: 100vh;
  background-color: #193149;

  .company-logo {
    max-width: 200px;
    margin-bottom: 10px;
  }

  .login-form-wrapper {
    padding: 0px;
    margin: 0px;
    border-radius: 3px;

    .welcome-ads {
      background-color: #eeeeee;
      width: 100%;
      height: 450px;
      border-radius: 3px 0 0 3px;
    }

    .login-form {
      padding: 15px 25px;

      .title {
        margin-top: 15px;
        margin-bottom: 25px;
      }

      .choice {
        display: flex;
        text-align: left;
        justify-content: left;
        align-items: center;

        .choice-text {
          display: flex;
          flex-direction: column;
          justify-content: left;
          margin-left: 10px;
        }
      }
    }
  }
}
