.privileges-content {
  .permission-section{
    padding: 3px;
    margin: 3px 0;
    background-color:#f4f8fd;
  }
  .permission-title {
    font-weight: 600;
  }
  .permission-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .permission-list li {
    margin-left: 5px;
  }
  .permission-list li .form-check-label {
    cursor: pointer;
    user-select: none;
  }
}
