.new-stock-item {
  margin: -10px;
  .nav-link {
    padding: 3px 15px;
  }
  .tab-content {
    height: 350px;
    padding: 10px;
  }
}
