@import "~bootstrap/scss/bootstrap";
html,
body {
  background-color: #e6e6e6;
  font-size: small;
  height: 100%;
}

.container,
.container-fluid,
.right-side-panel {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
.form-control:focus {
  box-shadow: inset 0 -1px 0 rgb(255, 255, 255);
}

label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 2px;
}

.b-button {
  border-radius: 3px;
  margin-left: 5px;
  padding: 4px 8px;
  svg {
    vertical-align: bottom;
    height: 18px;
  }
}
.b-button:focus {
  outline: none;
  box-shadow: none;
}

/*custom file upload*/
.upload-image-preview {
  img {
    max-height: 100px;
  }
}
.upload-image-file {
  input[type="file"] {
    display: none;
  }
  svg {
    width: 16px;
    height: 16px;
  }
  label {
    cursor: pointer;
  }
}

/*set disable non clickable*/
.disabled {
  pointer-events: none;
  opacity: 0.65;
}

/*workplace Editor*/
.doc-editor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 15px;

  width: 100%;
  height: 100%;
  padding-top: 15px;

  .doc-editor-workplace {
    background-color: #ffffff;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    border-radius: 3px;
    width: 75%;
    min-width: 570px;
    max-width: 900px;
    padding: 15px 10px;
    min-height: 700px;
    margin-bottom: 50px;
    margin-left: 10px;

    .workplace-footer {
      padding-top: 10px;
      padding-bottom: 25px;
    }
  }

  .doc-editor-control-wrapper {
    width: 25%;
    min-width: 185px;
    max-width: 350px;
    height: 100%;
    margin-right: 10px;

    .doc-editor-control {
      width: 25%;
      min-width: 185px;
      max-width: 350px;
      margin-right: 10px;
      position: fixed;
      top: 0;
      height: 100vh;
      padding-top: 110px;
      padding-bottom: 15px;

      .control-content {
        background-color: rgb(242, 241, 247);
        box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
        border-radius: 3px;
        width: 100%;
        height: 100%;
        padding: 10px;

        .title {
          font-size: 20px;
        }
        .action-control {
          display: flex;
          align-items: center;
          flex-direction: row;
          float: right;

          .dropdown-item {
            padding: 6px 8px;
          }
        }
      }
    }
  }
}
