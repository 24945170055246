.dashboard-error {
  margin-top: 15px;
  .module-wrapper {
    background-color: #ffffff;
    margin: 5px;
    padding: 10px;
    border-radius: 3px;
    min-height: 400px;
  }
  .main-section {
    text-align: center;
    .title {
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }
  .side-section {
    text-align: left;
    .title {
      margin-top: 5px;
      margin-bottom: 15px;
    }
  }
}
