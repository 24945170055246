.notify-wrapper {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 300px;
  min-height: 80px;
  padding: 5px;
  background-color: #272822;
  color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  z-index: 1999;
  animation: fadeInUp;
  animation-duration: 300ms;

  .notify-header {
    display: flex;

    .notify-icon {
      height: 20px;
      width: auto;
    }
    .info {
      fill: #b6d6f8;
    }
    .error {
      fill: #ffb4b4;
    }
    .success {
      fill: #baffb4;
    }

    .notify-title {
      margin-left: 5px;
      font-size: 16px;
    }
    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
      background-color: transparent;
      border: none;
      padding: 0;
      svg {
        fill: #8a8a8a;
        height: 21px;
        width: auto;
      }
    }
  }
  .notify-content {
    padding: 5px 5px;
    color: #d4d4d4;
    box-sizing: border-box;
    ul {
      margin: 0;
      padding-left: 20px;
      li {
        margin: 0;
      }
    }
  }
}
