.home {
  padding-top: 15px;

  .module-wrapper {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 3px;
    margin: 5px;
    min-height: 220px;
  }

  .main-section {
    text-align: center;
    .title {
      margin-top: 5px;
      margin-bottom: 15px;
    }
    .line {
      display: flex;
      justify-content: center;
      margin-top: 40px;

      .btn {
        .text {
          display: block;
          word-wrap: break-word;
          width: 60px;
        }
      }
    }
  }
  .side-section {
    text-align: left;
    .title {
      margin-top: 5px;
      margin-bottom: 15px;
    }
  }

  .new-wrapper {
    height: 300px;
    overflow-y: scroll;
  }
}
