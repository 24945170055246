.option_model {
  animation: zoomIn;
  animation-duration: 300ms;

  .modal-header {
    padding: 3px 10px;
    background-color: #272822;
    border-color: #272822;

    .modal-title {
      font-size: 12px;
      color: #fff;
      svg {
        height: 16px;
        width: 16px;
        vertical-align: text-bottom;
        margin-right: 5px;
      }
    }

    .close {
      svg {
        fill: #fff;
      }
    }
  }

  .modal-footer {
    padding: 0px;
  }
}
