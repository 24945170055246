.user-menu-toolbar {
  position: fixed;
  top: 5px;
  right: 0;
  display: flex;
  color: #fff;

  .user-name-wrapper {
    margin-top: 10px;
    margin-right: 5px;
  }
  .user-image-wrapper {
    margin-top: 3px;
    margin-right: 10px;
    background-color: #e8edf4;
    border-radius: 50px;
    padding: 3px;
    overflow: hidden;
    cursor: pointer;
    z-index: 99;
    img {
      height: 32px;
      width: 32px;
      border-radius: 32px;
    }

    &:hover {
      background-color: #3871c5;
    }
  }

  .user-toolbar-dropdown {
    position: absolute;
    width: 240px;
    height: 260px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    top: 32px;
    right: 10px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;

    .profile-image {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: 15px;
      img {
        width: 70px;
        height: 70px;
        border: 3px solid #3871c5;
        border-radius: 70px;
      }
      h1 {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: 600;
      }
      p {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 16px;
      }
    }

    .dropdown-item {
      margin-bottom: 5px;
      svg {
        vertical-align: bottom;
      }
    }
  }

  &:has(.user-toolbar-dropdown) > .user-image-wrapper {
    background-color: #3871c5;
  }
}
