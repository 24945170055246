/*table*/
.workplace-table {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding-top: 15px;
  padding-bottom: 5px;

  .sub-title {
    margin-left: 30px;
  }
  .table {
    width: 100%;

    .col-first {
      border: none;
      border-right: 1px solid #dee2e6;
      width: 30px;
    }
    .col-item {
      border-bottom: none;
      border-right: 1px solid #dee2e6;
      font-size: 12px;
    }
    .col-qty {
      border-bottom: none;
      border-right: 1px solid #dee2e6;
      font-size: 12px;
      width: 120px;
    }
    .col-rate {
      border-bottom: none;
      border-right: 1px solid #dee2e6;
      font-size: 12px;
      width: 130px;
    }
    .col-amount {
      border-bottom: none;
      border-right: 1px solid #dee2e6;
      font-size: 12px;
      width: 120px;
      text-align: right;
    }
    .col-last {
      border: none;
      font-size: 12px;
      width: 30px;
    }
    .row-first {
      border: none;
      border-right: 1px solid #dee2e6;
    }
    .row-item,
    .row-qty,
    .row-rate,
    .row-amount {
      border-right: 1px solid #dee2e6;
      border-bottom: 1px solid #dee2e6;
      padding: 2px;
      .form-control {
        border: none;
        padding: 2px 6px;
        &:disabled {
          background-color: #ffffff;
        }
      }
    }
    .row-amount {
      .form-control {
        text-align: right;
      }
    }

    .row-qty {
      .meta-unit,
      .meta-qty {
        font-size: 11px;
        margin: 0;
      }
    }
    .row-last {
      border: none;
      padding: 0px;
      .side-button {
        display: flex;
        align-items: center;
        padding: 3px;
        cursor: pointer;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .drop-item {
    display: flex;
    .sku {
      width: 100px;
    }
    .name {
      width: 200px;
    }
    .type {
      width: 30px;
    }
    .price {
      width: 80px;
    }
    .qty {
      width: 80px;
    }
    .new {
      width: 100%;
      align-items: baseline;
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  .table-footer {
    margin-left: 30px;
    margin-right: 30px;

    .total-wrapper {
      background-color: #f7f7f7;
      border-radius: 3px;
      padding: 10px 8px;

      .sub-total-row {
        label {
          font-weight: 600;
          font-size: 12px;
        }
        .sub-value {
          text-align: right;
          p {
            margin-bottom: auto;
          }
        }
      }
      .discount-row {
        height: 36px;
        label {
          font-weight: 600;
          font-size: 12px;
        }
        .sub-value {
          text-align: right;
          p {
            margin-bottom: auto;
          }
        }
      }
      .tax-row {
        height: 36px;
        label {
          font-weight: 600;
          font-size: 12px;
        }
        .sub-value {
          text-align: right;
          p {
            margin-bottom: auto;
          }
        }
      }
      .total-row {
        height: 25px;
        label {
          font-weight: 600;
          font-size: 16px;
        }
        .sub-value {
          text-align: right;
          p {
            margin-bottom: auto;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
    }
  }
}
