.brand-list {
  margin-top: 15px;
  .module-wrapper {
    background-color: #ffffff;
    margin: 5px;
    border-radius: 3px;
    min-height: 400px;

    .main-section {
      .action-table-title {
        background-color: #f2f1f7;
        padding: 5px;
        border-radius: 3px;
        text-align: center;
        h3 {
          margin: 0;
          font-size: 16px;
          color: #7c7c7c;
        }
      }
      .action-table {
        padding: 5px 10px;
      }
    }
    .side-section {
      padding: 10px;
      .title {
        font-size: 24px;
      }
      p {
        color: #7c7c7c;
      }
    }
  }
}

.table-action-control {
  width: 100%;
  text-align: right;
  .row-btn {
    width: 28px;
    height: 28px;
    background-color: #f2f1f7;
    border-radius: 25px;
    padding: 2px 2px;
    margin-right: 5px;
    svg {
      width: 14px;
      height: 14px;
    }
    &:hover {
      background-color: #dbf0ff;
    }
  }
}
