.right-side-panel {
  position: fixed;
  top: 0;
  right: -35%;
  background-color: #ffffff;
  width: 35%;
  height: 100%;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  padding-top: 115px;
  padding-left: 10px;
  padding-right: 5px;
  padding-bottom: 10px;

  .close-button {
    position: absolute;
    right: 15px;
  }

  h6 {
    background-color: #f7f7f7;
    padding: 3px 0px;
    border-radius: 3px;
  }
  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 6px;
    margin-bottom: 5px;
    .header-img {
      img {
        height: 40px;
        width: auto;
      }
    }
    .header-text {
      margin-left: 10px;
      h4 {
        margin-bottom: 0;
        font-size: 16px;
        text-transform: uppercase;
      }
      p {
        margin-bottom: 0;
      }
    }
  }

  .right-content {
    height: 100%;
    width: 100%;
  }

  .info-section {
    margin-bottom: 10px;
    .info-field {
      p {
        margin-bottom: 0px;
      }
      .name {
        word-wrap: break-word;
      }
      .value {
        word-wrap: break-word;
      }
    }

    .badge-status {
      display: block;
      background-color: #bbe9ff;
      width: fit-content;
      padding: 3px 15px;
      color: #333333;
      font-weight: 600;
      border-radius: 50px;
      height: 25px;
      overflow: hidden;
    }
  }

  .trans-content {
    padding-bottom: 220px;

    .table thead th {
      font-weight: 400;
    }
    .table tbody td p {
      margin: 0px;
      color: #313131;
    }
  }

  .action-section {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 50px;
    z-index: 99;
    text-align: right;
    padding: 10px;
    background-color: #ffffff;
    .action-control {
      display: flex;
      align-items: center;
      flex-direction: row;
      float: right;
      .dropdown-item {
        padding: 6px 8px;
      }
    }
  }
}

.show-right-side-panel {
  max-width: 65%;
  margin-right: 35%;

  .right-side-panel {
    right: 0px;
  }
}
