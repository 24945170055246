.new-complex-item {
  margin: -10px;
  .nav-link {
    padding: 3px 15px;
  }
  .tab-content {
    height: 350px;
    padding: 10px;

    .material-table-wrapper{
      background-color: #fff;
      border-left: 1px solid #dee2e6;
      border-bottom: 1px solid #dee2e6;
      display: block;
      overflow-x: hidden;
      overflow-y:scroll;
      height: 300px;
      padding-bottom: 100px;
      
      .material-table{
        thead th {
          font-weight: 400;
        }
        .tbl-item-col{
          width: 50%;
        }
        .tbl-qty-col{
          width: 20%;
        }

        .side-button{
          padding: 3px;
          cursor: pointer;
          text-align: center;
        }
      }
    }
    
  }
}
