.dashboard {
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding-top: 95px;

  .notifier-wrapper {
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 1999;
  }
}
