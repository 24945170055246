.main-menu {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  .menu-bar {
    width: 100%;
    background-color: #2a579a;

    .tab-bar {
      padding-top: 10px;

      .tab-content {
        background-color: #e8edf4;
      }

      .nav-tabs {
        padding-left: 10px;
        border: none;
      }

      .nav-link {
        padding: 2px 20px;
        border-radius: 3px 3px 0 0;
        color: #fff;
        background-color: #2a579a;
        border: none;
        margin-bottom: 0;
        margin-right: 3px;
        user-select: none;
        cursor: pointer;

        &:hover {
          color: #fff;
          background-color: #3871c5;
          border-top-color: #2a579a;
          border-right-color: #2a579a;
          border-left-color: #2a579a;
        }
      }

      .active {
        color: #495057;
        background-color: #e8edf4;
        border-color: #e8edf4;

        &:hover {
          color: #495057;
          background-color: #e8edf4;
          border-color: #e8edf4;
        }
      }

      .tab-content {
        .tab-pane {
          background-color: #e8edf4;
          padding: 3px 15px;
          border: none;

          .toolbar-section {
            border-right: 1px solid #cecdcd;
            height: 55px;
            width: 100%;
            padding: 0 15px;

            .toolbar-button {
              padding: 2px 8px;
              display: block;
              text-align: center;
              border-radius: 3px;
              font-size: 13px;
              margin-right: 2px;
              img {
                display: block;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
              }
              .text {
                display: block;
                color: #414141;
              }
              &:hover {
                background-color: #cecdcd;
              }
              &:focus {
                box-shadow: none;
                background-color: #cecdcd;
              }
            }

            .toolbar-button-inline {
              padding: 2px 6px;
              display: block;
              text-align: left;
              border-radius: 3px;
              font-size: 13px;
              img {
                margin-right: 2px;
              }
              .text {
                color: #414141;
              }
              &:hover {
                background-color: #cecdcd;
              }
              &:focus {
                box-shadow: none;
                background-color: #cecdcd;
              }
            }

            .toolbar-button-drop {
              padding: 3px 6px;
              display: block;
              text-align: left;
              border-radius: 3px;
              font-size: 13px;
              background-color: transparent;
              border: none;
              color: #414141;

              img {
                margin-right: 2px;
              }
              .text {
                color: #414141;
              }
              &:hover {
                background-color: #cecdcd;
              }
              &:focus {
                box-shadow: none;
                background-color: #cecdcd;
              }
            }
            .toolbar-button-drop-item {
              padding: 3px 6px;
              display: block;
              width: 100%;
              text-align: left;
              white-space: nowrap;
              border-radius: 0;
              font-size: 13px;
              background-color: transparent;
              border: none;
              color: #414141;

              img {
                margin-right: 2px;
              }
              .text {
                color: #414141;
              }
              &:hover {
                background-color: #cecdcd;
              }
              &:focus {
                box-shadow: none;
                background-color: #cecdcd;
              }
            }
          }
        }
      }
    }
  }
}
